







import { Vue, Component } from 'vue-property-decorator';
import ContentHierarchy from '@/components/resources/Settings/Hierarchy/ContentHierarchy.vue';

@Component({
  components: { ContentHierarchy },
})
export default class Settings extends Vue {

}
