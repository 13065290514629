





























import { Vue, Component } from 'vue-property-decorator';
import { HierarchyNode } from '@/types/resources/HierarchyNode';
import { defaultHierarchyNode } from '@/utils/hierarchyNodes';
import { asyncForEach } from '@/utils/asyncForEach';

@Component
export default class ContentHierarchy extends Vue {
  private loading = true;

  private saving = false;

  private message: { type: string; text: string } = {
    type: 'success',
    text: '',
  };

  private hierarchyNodes: Partial<HierarchyNode>[] = [];

  addNode(path: number | number[] | null) {
    if (path === null) {
      this.hierarchyNodes.push(defaultHierarchyNode(this.hierarchyNodes.length, true));
    } else if (Array.isArray(path) && path?.length) {
      let pushTo = this.hierarchyNodes[path[0]];

      if (path.length > 1) {
        for (let i = 1; i < path.length; i += 1) {
          if (pushTo.children) {
            pushTo = pushTo.children[path[i]];
          }
        }
      }

      if (Array.isArray(pushTo.children)) {
        pushTo.children.push(defaultHierarchyNode(pushTo.children.length));
      }
    }
  }

  async removeNode(path: number[]) {
    let removeFrom = this.hierarchyNodes[path[0]];

    if (path.length > 1) {
      for (let i = 1; i < path.length - 1; i += 1) {
        if (removeFrom.children) {
          removeFrom = removeFrom.children[path[i]];
        }
      }
    }

    if (removeFrom && Array.isArray(removeFrom.children)) {
      let nodeToRemove: Partial<HierarchyNode>;
      if (path.length === 1) {
        nodeToRemove = this.hierarchyNodes[path[0]];
      } else {
        nodeToRemove = removeFrom.children[path[path.length - 1]];
      }

      if (nodeToRemove && nodeToRemove['@id']) {
        try {
          await this.$api.delete(nodeToRemove['@id']);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e, e.response, e.response.data);
          this.message = {
            type: 'danger',
            text: this.$t('settings.hierarchy.errorWhenDeletingNode') as string,
          };
        }
      }

      if (path.length === 1) {
        this.hierarchyNodes.splice(path[0], 1);
      } else {
        removeFrom.children.splice(path[path.length - 1], 1);
      }
    }
  }

  prepareChildren(children: Partial<HierarchyNode>[]): Partial<HierarchyNode>[] {
    return children.map((c) => ({
      ...c,
      '@id': undefined,
      id: c['@id'] ?? undefined,
      children: c.children ? this.prepareChildren(c.children) : [],
    }));
  }

  async submit() {
    this.saving = true;

    try {
      await asyncForEach(this.hierarchyNodes, async (node) => {
        if (node['@id']) {
          await this.$api.put(node['@id'], {
            ...node,
            id: undefined,
            template: `${node.template}`,
            children: this.prepareChildren(node.children),
          });
        } else {
          await this.$api.post('/api/hierarchy_nodes', node);
        }
      });
      this.message = { type: 'success', text: this.$t('global.saved') as string };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      this.message = {
        type: 'danger',
        text: e.response?.data?.['hydra:description'] ?? (this.$t('global.unknownError') as string),
      };
    }

    try {
      await this.$store.dispatch('hierarchy/loadHierarchyNodes');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e, e.response, e.response.data);
      this.message = {
        type: 'danger',
        text: this.$t('settings.hierarchy.savedButErrorWhileLoading') as string,
      };
    }

    this.saving = false;
  }

  convertObjectsToIds(node: Partial<HierarchyNode>) {
    let { template } = node;
    if (node.template && typeof node.template === 'object') {
      template = node.template['@id'];
    }
    let { segment } = node;
    if (node.segment && typeof node.segment === 'object') {
      segment = node.segment['@id'];
    }

    let { children } = node;
    if (node.children?.length) {
      children = node.children.map((child) => ({
        ...this.convertObjectsToIds(child),
      })) as Partial<HierarchyNode>[];
    }

    return {
      ...node, children, template, segment,
    };
  }

  async mounted() {
    try {
      const response = await this.$api.get('/api/hierarchy_nodes?exists[parent]=false');
      if (response.status === 200 && response.data['hydra:member']) {
        this.hierarchyNodes = response.data['hydra:member'].map((node: HierarchyNode) => ({
          ...this.convertObjectsToIds(node),
        }));
        this.loading = false;
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      this.loading = false;
    }
  }
}
